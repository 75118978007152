.modal-backdrop {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 999; 
  }

  .modal {
    position: fixed;
    top: 35%;
    left: 40%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 70%;
    max-width: 60vw;
    max-height: 80vh;
    overflow-y: auto; 
    z-index: 1000;
    margin: 50px 10px 10px 10px;
  }
  